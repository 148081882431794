window._ = require('lodash');
try {
    window.$ = window.jQuery = window.jquery = require('jquery');
    require('jquery-migrate');
    require('popper.js');
    require('bootstrap');
    require('slick-carousel');
    require('sweetalert');

    require('../../resources/assets/template/vendor/cubeportfolio-full/cubeportfolio/js/jquery.cubeportfolio.js');
    require('../../resources/assets/template/js/hs.core');
    require('../../resources/assets/template/js/components/hs.scroll-nav');
    require('../../resources/assets/template/js/components/hs.carousel');
    require('../../resources/assets/template/js/components/hs.header');
    require('../../resources/assets/template/js/components/hs.cubeportfolio');
    require('../../resources/assets/template/js/components/hs.go-to');

	$(document).on('ready', function () {
        $.HSCore.components.HSHeader.init($('#js-header'));
        $.HSCore.components.HSCarousel.init('.js-carousel');
        $.HSCore.components.HSGoTo.init('.js-go-to');
        $.HSCore.components.HSScrollNav.init($('#js-scroll-nav'), {
            duration: 700
        });
        $.HSCore.components.HSCubeportfolio.init('.cbp');

        // let showme = true;
        // if ( showme )
        // {
        //   swal(
        //       '¡Cambio de dirección de envio!',
        //       '¡Recuerda actualizar tus envíos a nuestra nueva dirección!',
        //       'info'
        //     )
        // }

        if( process.env.NODE_ENV == 'production' )
        {
          require('./googletagmanager');
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'UA-52982146-12');
          console.clear();
        }
      });
} catch (e) {
    console.warn( 'Problem: ' + e );
}

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
